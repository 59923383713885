import React from "react";
import Text from "../../../components/Typography/Index";
import Container from "../../../components/Ui/Container";
import images from "../../../constants/Images";

const ChooseUs = ({ title, description, points }) => {
  return (
    <section className="w-full relative  flex-1 pt-[24px] md:pt-[32px] pb-[82px] bg-[#FAFAFB]  ">
      <Container className="grid lg:grid-cols-[1fr,1fr] gap-6 items-center ">
        <div>
          <Text className={"text-secondary"} variant="heading">
            {title}{" "}
          </Text>
          <Text className={"text-grey mt-2 md:mt-[46px]"} variant="subheading">
            {description}
          </Text>
          <ul className="flex flex-col mt-2 md:mt-6 pl-2 ">
            {points?.map((point, ind) => (
              <li key={ind} className="flex items-center gap-2">
                <div className="w-[5px] h-[5px] bg-dark rounded-full " />
                <Text className={"text-grey"} variant="subheading">
                  {point}
                </Text>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden overflow-hidden relative md:pt-[162px] justify-end  md:flex ">
          <div className=" md:w-[505px] md:h-[536px]">
            <div className="absolute w-full h-full top-8 flex justify-end md:justify-center  ">
              <img
                src={images.chooseUs1}
                className="w-[140px] h-[148px] md:w-[308px] md:h-[325px]   "
                alt="choose us"
              />
            </div>
            <img
              src={images.chooseUs2}
              className="absolute w-[193px] h-[133px] md:w-[429px] md:h-[291px] -left-4 bottom-8 self-center "
              alt="choose us"
            />
            <img src={images.chooseUsBg} alt="choose us" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ChooseUs;
