class Themes {
  static fontSizes = {
    title: "56px",
    "mobile-title": "20px",
    heading: "40px",
    "mobile-heading": "16px",
    subHeading: "24px",
    "mobile-subHeading": "14px",
    heroDescription: "24px",
    "mobile-heroDescription": "14px",
    button: "20px",
    "mobile-button": "14px",
    footerHeading: "19px",
  };
  static fontFamilys = {};
  static colors = {
    primary: "#F28420",
    secondary: "#134351",
    grey: "#455A64",
    titleText: "#F28420",
    descriptionText: "#455A64",
    light: "#ffffff",
    dark: "#000000",
  };
}

export default Themes;
