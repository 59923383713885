import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../styles/Carousel.css";

const responsives = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    partialVisibilityGutter: 40,
  },
  largeLaptop: {
    breakpoint: { max: 3000, min: 1440 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  laptop: {
    breakpoint: { max: 1440, min: 1200 },
    items: 3,
    partialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 1200, min: 464 },
    items: 2,
    partialVisibilityGutter: 80,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};
// a slide object contains the image link, title and function/click event for when a user clicks on a card

const Slider = ({ data = [], children, responsive, ...restProps }) => {
  const responsiveCopy = { ...responsives, ...responsive };

  return (
    <Carousel {...restProps} slides={data} responsive={responsiveCopy}>
      {children}
    </Carousel>
  );
};

export default Slider;
