import React from "react";
import { contactUs } from "../../../constants";
import Text from "../../../components/Typography/Index";
import Container from "../../../components/Ui/Container";
import Input from "../../../components/Input";
import Primary from "../../../components/button/Primary";
import Themes from "../../../constants/Themes";

const ContactUs = () => {
  return (
    <section className="w-full  relative py-[30px] md:py-[100px]">
      <Container className="grid lg:grid-cols-[1fr,1.6fr] gap-8 md:gap-[85px] items-center ">
        <div>
          <div
            className=""
            style={{
              borderLeft: "3.5px solid " + Themes.colors.primary,
            }}
          >
            <Text variant="subheading" className={"!text-primary ml-2"}>
              Contact Us
            </Text>
          </div>
          <Text
            className={"text-secondary mt-[14px] font-bold "}
            variant="heading"
          >
            Get in touch with us
          </Text>
          <Text className={"text-secondary mt-[4px]"} variant="subheading">
            Join us in shaping a future where success knows no bounds.
          </Text>
          <div className=" mt-[24px] md:mt-[48px] flex flex-col gap-[24px] md:gap-[36px]">
            {contactUs?.map((item, ind) => (
              <div key={ind} className="flex flex-row items-center gap-[16px]">
                <div className="w-[63px] h-[63px] bg-primary rounded-full flex justify-center items-center ">
                  {/* icon */}
                  <img src={item?.icon} className="w-[24px] h-[24px] " />
                </div>
                <div>
                  <Text
                    className={"text-secondary  !text-[16px] !leading-tight "}
                    variant="subheading"
                  >
                    {item?.label}
                  </Text>
                  <Text
                    style={{ textDecoration: item?.decoration && "underline" }}
                    className={`text-secondary font-semibold`}
                    variant="subheading"
                  >
                    <a href={item?.isMail ? `mailto:${item?.value}` : item?.isPhone && `tel:${item?.value}`}>
                      {item?.value}
                    </a>
                  </Text>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="grid md:grid-cols-[1fr,1fr] gap-[16px] md:gap-[28px]">
            <Input label={"Your Name"} />
            <Input label={"Email"} />
            <Input label={"Your Phone Number"} />
            <Input label={"City"} />
          </div>
          <div className="mt-[16px] md:mt-[28px]">
            <Input label={"Your Message"} isTextArea={true} />
          </div>
          <div className="mt-[30px]">
            <Primary className="py-[16px]">Submit Message</Primary>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
