"use client";

const variants = {
  title: {
    type: "h1",
    className:
      "text-mobile-title  md:text-title text-secondary md:leading-[65px]",
    style: {
      fontWeight: 600,
    },
  },
  heading: {
    type: "h2",
    className:
      "text-mobile-heading md:text-heading leading-normal md:leading- text-secondary leading-normal font-medium leading-tight",
    style: {},
  },

  subheading: {
    type: "p",
    className:
      "text-mobile-subHeading md:text-subHeading leading-normal md:leading-[32px] font-normal text-dark",
    style: {},
  },
  heroDescription: {
    type: "p",
    className:
      "text-mobile-heroDescription md:text-heroDescription text-descriptionText leading-normal md:leading-[32px]",
    style: {
      fontWeight: 400,
    },
  },
  testDescription: {
    type: "p",
    className:
      "text-heading-mobile md:text-[18px] text-descriptionText leading-normal md:leading-[32px] text-[#3D3D3D]  leading-tight ",
    style: {
      fontWeight: 400,
    },
  },
  footerHeading: {
    type: "p",
    className:
      "text-heading-mobile md:text-footerHeading leading-normal md:leading-[32px] text-light  leading-tight ",
    style: {
      fontWeight: 400,
    },
  },
};

const Text = ({ children, variant = "title", props, style, className }) => {
  const Type = variants[variant]?.type || "p";
  return (
    <Type
      variant={variants[variant]?.type}
      {...props}
      className={`${variants[variant]?.className} ${className} `}
      style={{ ...variants[variant]?.style, ...style }}
    >
      {children}
    </Type>
  );
};

export default Text;
