import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Highlights from "./components/Highlights";
import Model from "./components/Model";
import Features from "./components/Features";
import HowItWorks from "./components/HowItWorks";

import * as Sentry from "@sentry/react";
import Hero2 from "./components/Hero2";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/home/components/Services";
import OurVision from "./pages/home/components/OurVision";
import Testimonials from "./pages/home/components/Testimonials";
import ChooseUs from "./pages/home/components/ChooseUs";
import { chooseUs } from "./constants";
import ContactUs from "./pages/home/components/ContactUs";
import Footer from "./layouts/Footer";
import { BrowserRouter } from "react-router-dom";
import { useState } from "react";

const App = () => {
  const [open, setOpen] = useState(false);

  return (
    <BrowserRouter>
      <main className="">
        <Navbar />
        <div className="pt-[90px] md:pt-[50px]" />
        <Hero2 handleOpen={() => setOpen(true)} />
        <div className="mt-4 md:mt-10">
          <Services {...{ open, setOpen }} />
        </div>
        <AboutUs />
        <ChooseUs {...chooseUs} />
        <div className="relative top-[-28px] ">
          <OurVision />
        </div>
        <div className="mt-[12px] md:mt-[48px]">
          <Testimonials />
        </div>
        <Highlights />
        <ContactUs />
        <Footer />
        {/* <Model />
      <Features />
      <HowItWorks />
      <Footer /> */}
      </main>
    </BrowserRouter>
  );
};

export default Sentry.withProfiler(App);
