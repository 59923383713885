import aboutUs from "../assets/images/aboutUs.png";
import card1 from "../assets/images/cards1.png";
import card2 from "../assets/images/monitor.png";
import testimonialProfile1 from "../assets/images/testimonial-profile.png";
import leftArrow from "../assets/images/left-arrow.png";
import rightArrow from "../assets/images/right-arrow.png";
import ovalshape from "../assets/images/ovalshape.png";
import chooseUs1 from "../assets/images/chooseus1.png";
import chooseUs2 from "../assets/images/chooseus2.png";
import chooseUsBg from "../assets/images/chooseUs-bg.png";
import sms from "../assets/images/sms.png";
import call from "../assets/images/call.png";
import location from "../assets/images/location.png";
import whiteArrowRight from "../assets/images/arrow-right.png";
import logo from "../assets/images/logo.png";
import menu from "../assets/images/menu.png";
import arrowbrokenLeft from "../assets/images/arrow-distressed-left.png";
import arrowbrokenRight from "../assets/images/arrow-distressed-right (1).png";
import closeCircleIcon from "../assets/close-circle.png";

//social media links
import blackFacebook from "../assets/black-facebook.png";
import blackInstagram from "../assets/black-instagram.png";
import blackTwitter from "../assets/black-twitter.png";
import blackYoutube from "../assets/black-youtube.png";
import facebookIcon from "../assets/icon_facebook.png";
import youtubeIcon from "../assets/icon_youtube.png";
import twitterIcon from "../assets/ri_twitter-x-line.png";
import instagram from "../assets/uil_instagram.png";

//appzort logo
import appzortNavbarLogo from "../assets/images/appzort.png";

const images = {
  aboutUs,
  card1,
  card2,
  testimonialProfile1,
  leftArrow,
  rightArrow,
  ovalshape,
  chooseUs1,
  chooseUs2,
  chooseUsBg,
  sms,
  call,
  location,
  whiteArrowRight,
  logo,
  blackFacebook,
  blackInstagram,
  blackTwitter,
  blackYoutube,
  facebookIcon,
  youtubeIcon,
  twitterIcon,
  instagram,
  menu,
  appzortNavbarLogo,
  arrowbrokenLeft,
  arrowbrokenRight,
  closeCircleIcon,
};
export default images;
