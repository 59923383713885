import { useEffect, useState } from "react";

const useScrollPercentage = (defaultPercentage) => {
  const [scrollPercentage, setScrollPercentage] = useState(defaultPercentage);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = window.scrollY;
      const percentage = (scrolled / scrollHeight) * 100;
      setScrollPercentage(percentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollPercentage;
};

export default useScrollPercentage;
