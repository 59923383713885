import React from "react";
import Container from "../../../../components/Ui/Container";
import Text from "../../../../components/Typography/Index";
import Cards from "../../../../components/Ui/Cards";
import images from "../../../../constants/Images";

const ServicesModal = ({ diveInServices, handleClose }) => {
  return (
    <section className="bg-white services__root">
      <div className="flex justify-end  sticky top-6 mr-6 z-50 ">
        <div className="cursor-pointer " onClick={handleClose}>
          <img src={images.closeCircleIcon} alt="close" />
        </div>
      </div>
      <Container className={"mt-[40px] "}>
        {/* <div className="sticky top-2 pt-2 z-10 bg-white"> */}
        <Text className={"text-left "}>
          Delivering Seamless Solutions through <br /> Holistic{" "}
          <span id="header" className="text-primary font-bold ">
            Design & Development
          </span>
          , Enhanced <br /> by Cutting-Edge{" "}
          <span id="header" className="text-primary font-bold ">
            Digital Innovations
          </span>
        </Text>
        {/* </div> */}
        {/* mapping the data */}
        <div className="mt-[42px] flex flex-col gap-[24px] md:gap-[42px]">
          {diveInServices?.map((item, key) => (
            <div
              key={key}
              className={`${key === 1 && "panel__services__section"}`}
            >
              <Text variant="heading" className={"text-left font-[700] "}>
                {item?.category}
              </Text>
              <div className="mt-[24px] grid lg:grid-cols-[1fr,1fr] gap-3 lg:gap-[24px] ">
                {item?.services?.map((service, key) => (
                  <Cards
                    {...service}
                    wrap={true}
                    key={key}
                    className={`!mx-0`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ServicesModal;
