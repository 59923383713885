import React from "react";
import Container from "../components/Ui/Container";
import images from "../constants/Images";
import Text from "../components/Typography/Index";
import { aboutUs } from "../constants";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const AboutUs = () => {
  useGSAP(() => {
    gsap.to(".aboutus__image", {
      scrollTrigger: ".aboutus__image",
      translateX: 0,
      delay: 0.2,
      opacity: 1.3,
      duration: 1,
      start: "top center", // Start the animation when the top of the trigger element reaches the center of the viewport
      end: "bottom center",
      toggleActions: "restart pause none none",
    });
  }, []);
  return (
    <section
      id="aboutus"
      className="w-full   relative overflow-hidden pb-4 md:py-4 mt-[20px]"
    >
      <Container>
        <div className="h-[100%] w-full gap-[38px] md:gap-[48px]  grid lg:grid-cols-[1.1fr,1.6fr]  items-center lg:mt-[80px] md:mb-[43px]">
          {/* about us image section */}
          <div className="h-full px-[50px] md:px-[90px] lg:px-0 order-1 lg:order-none  aboutus__image translate-x-[-20px] opacity-0 ">
            <img src={images.aboutUs} />
          </div>
          {/* abouts us content */}
          <div className="translate-x-[20px] aboutus__image opacity-0 ">
            <Text variant="subheading" className={"!text-primary "}>
              About Us
            </Text>
            <Text
              variant="heading"
              className={"mt-2 md:mt-[16px] pr-[100px] lg:pr-0 font-semibold "}
            >
              We're a group of creative minds, crafting innovative solutions
              across all facts of operations.
            </Text>
            <div className="mt-6 md:mt-[54px] grid grid-cols-2 gap-y-[24px] lg:gap-y-[54px] w-10/12 lg:w-4/5 ">
              {/* list of values */}
              {aboutUs?.map((item, ind) => (
                <div key={ind} className="flex flex-row gap-4 items-center ">
                  <div className="h-full md:h-[110px] w-[8px] bg-gradient-to-b from-orange-100 to-orange-600 rounded-[4px] overflow-hidden " />
                  <div>
                    <Text
                      variant="heading"
                      className={
                        "mt-[16px] leading-normal md:!text-[32px] !font-bold !text-secondary"
                      }
                    >
                      {item?.label}
                    </Text>
                    <Text
                      variant="subheading"
                      className={"mt-2 md:mt-[24px] !text-grey"}
                    >
                      {item?.value}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
      <div
        className="blurry w-[180px] h-[180px] md:w-[338px] md:h-[338px] bg-primary absolute bottom-[-100px] right-0 blur-[550px] "
        style={
          {
            // filter: "blur(550px)",
          }
        }
      ></div>
    </section>
  );
};

export default AboutUs;
