import React from "react";

const Input = ({
  label,
  value,
  onChange,
  placeholder,
  isTextArea,
  className,
  ...restProps
}) => {
  const Component = isTextArea ? "textarea" : "input";
  return (
    <div>
      <label
        for="first_name"
        class="block mb-2 text-footerHeading font-medium text-gray-900 dark:text-white"
      >
        {label}
      </label>
      <Component
        {...restProps}
        type="text"
        id="first_name"
        class={
          "bg-light border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" +
          " " +
          className
        }
        placeholder={placeholder}
        required
      />
    </div>
  );
};

export default Input;
