import React from "react";
import Container from "../../../components/Ui/Container";
import Text from "../../../components/Typography/Index";
import { ourVision } from "../../../constants";

const OurVision = () => {
  return (
    <div className="relative flex flex-col items-center py-[30px]">
      <div className="bg-primary text-center py-2 pb-7 md:py-[40px] relative z-10">
        <Text variant="heading" className={" !font-bold !text-light"}>
          {ourVision?.title}
        </Text>
        <Text
          variant="subheading"
          className={"mt-[24px] text-light lg:px-[240px] "}
        >
          {ourVision?.description}
        </Text>
      </div>
      <Container className="z-0 absolute h-[100%] w-full top-[0px] bottom-[0px]  md:px-[150px]  ">
        <div className=" border-[3px] h-[100%] border-primary  " />
      </Container>
    </div>
  );
};

export default OurVision;
