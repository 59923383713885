import React from "react";
import images from "../../constants/Images";
import Text from "../Typography/Index";
import { urlFor } from "../../config/client";

const Cards = (props) => {
  const { icon, color, title, description, ind, className, wrap } = props;
  return (
    <div
      className={
        "drop-shadow bg-light flex flex-col p-[32px]  lg:pb-[40px] md:pb-[70px] rounded-[20px] h-full my-2 mx-4 2xl:mx-[24px] " +
        " " +
        className
      }
    >
      <div
        style={{ backgroundColor: color }}
        className="flex justify-center items-center  rounded-full w-[59px] h-[59px]  md:w-[108px] md:h-[108px] "
      >
        <img
          src={icon && urlFor(icon)}
          className="w-[42px] h-[42px] md:w-[52px] md:h-[52px] "
        />
      </div>
      <Text
        variant="subheading"
        className={`mt-[24px]  !font-bold !text-secondary ${
          !wrap ? "lg:w-4/6" : ""
        } whitespace-pre-wrap `}
      >
        {title}
      </Text>
      <div className="w-2/6 md:w-1/5 h-[4px] bg-primary mt-[16px] " />
      <Text variant="subheading" className={"mt-[24px] !text-grey"}>
        {description}
      </Text>
    </div>
  );
};

export default Cards;
