import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";
import Text from "./Typography/Index";
import Container from "./Ui/Container";
import Primary from "./button/Primary";
import SvgImage from "./Ui/SvgImage";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import LottiePlayer from "./lottieplayer/Index";
import lottieImage from "../assets/Animation - 1712903490064 (1).json";
import images from "../constants/Images";
import Typewriter from "typewriter-effect";
import { provides } from "../constants";

const Hero2 = ({ handleOpen }) => {
  useGSAP(() => {
    gsap.to("#hero", {
      translateY: 0,
      ease: "linear",
      duration: 1,
      opacity: 1,
      delay: 0.5,
    });
  }, []);
  return (
    <section
      id="hero"
      className="w-full md:mt-[100px] 2xl:mt-[0px]  relative translate-y-[50px] md:translate-y-[100px] opacity-0 "
    >
      <SvgImage
        className="absolute z-0 bottom-0 lg:bottom-[-20px] xl:bottom-[-35px] md:block"
        color="#FDF6EE"
      />
      <Container className={"md:!pr-0 z-10 relative  "}>
        <div className="h-[100%] w-full  grid lg:grid-cols-[1.9fr,2.4fr]  items-start">
          {/* container 1 with heading texts */}
          <div className="md:pt-[70px] xl:pt-[100px]">
            <Text className={"text-center px-[24px] md:px-0 md:text-left "}>
              We're expertise in
              <span id="header" className="text-primary font-bold ">
                <Typewriter
                  options={{
                    strings: provides,
                    autoStart: true,
                    loop: true,
                    delay: 100,
                    cursor: "",
                    pauseFor: 1000,
                    wrapperClassName: "typewrite__main",
                  }}
                />
              </span>
              Which helps digital future of your Business
            </Text>
            <Text
              variant="heroDescription"
              className={"mt-2 md:mt-6  text-center  md:text-left "}
            >
              Our innovative approach ensures tailored solutions delivered with
              precision and efficiency, maximizing your success in the digital
              sphere.
            </Text>
            <div className="w-full flex md:block justify-center ">
              <Primary className=" mt-[24px] md:mt-[40px] " onClick={handleOpen}>
                Explore{" "}
                <img
                  src={images.whiteArrowRight}
                  className="lg-[16px] h-[16px] md:w-[24px] md:h-[24px] ml-[10px] "
                />
              </Primary>
            </div>
          </div>
          <div className="flex self-center relative">
            {/* lottie player */}
            <LottiePlayer
              style={{ display: "flex", alignItems: "start" }}
              src={lottieImage}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero2;
