import React from "react";

const Primary = ({
  children,
  variant = "primary",
  className = "",
  ...restProps
}) => {
  const variants = {
    primary: {
      className: "text-white bg-primary text-button",
    },
    secondary: {
      className:
        " bg-[#EFEFF2] text-secondary rounded-full text-button font-semibold",
    },
  };
  return (
    <button
      {...restProps}
      type="button"
      className={
        " hover:scale-[0.96] focus:ring-4 focus:ring-blue-300 font-medium rounded-[16px] px-[32px] flex items-center leading-normal py-[12px] dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 text-mobile-button md:text-button " +
        variants[variant]?.className +
        " " +
        className
      }
      style={{
        transition: "background-color 0.4s ease",
      }}
    >
      {children}
    </button>
  );
};

export default Primary;
