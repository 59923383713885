import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "q8co90e4",
  dataset: "production",
  apiVersion: "2024-04-27",
  useCdn: true,
  // token: process.env.NEXT_PUBLIC_SANITY_TOKEN,
  token:
    "skfomiJjVtmXtap1zp1aAtDQWDmnDjzBdUnuNhEpcjeu1b0SE2CGf3RDm86O7qiPhivtoFsJ180Lt8stzhhxdnvFsI0xV74w7q8eY9TBZaxEsZrAq0MVwXUJk2EuFC65ULHmuLciSbqJwa4ohpxGBARs9nMzQPKRsAL3k3SmxX9yYYzoh3jv",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
