"use client";
import Text from "../components/Typography/Index";
import React from "react";
import Container from "../components/Ui/Container";
import { socialMediaLinks, footerData, navs } from "../constants";
import Stack from "../components/common/Stack";
import { Link } from "react-router-dom";
import images from "../constants/Images";
import Themes from "../constants/Themes";

const Footer = () => {
  return (
    <footer className="bg-secondary md:py-[44px] pt-[44px] pb-[60px]">
      <Container>
        <div
          container
          className="md:py-[10px] grid grid-cols-1 lg:grid-cols-4 gap-8 md:gap-4 "
        >
          <div item xs={12} md={3}>
            <Stack className=" gap-4">
              <Stack className="md:w-3/5">
                <Stack className="bg-light rounded-[100%]  h-[130px] w-[130px] flex items-center justify-center self-center ">
                  <img
                    style={{
                      borderRadius: "100%",
                      width: "130px",
                      height: "130px",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                    src={images.logo}
                  />
                </Stack>
              </Stack>
            </Stack>
          </div>
          {/* nav links */}
          <div
            item
            xs={12}
            md={2}
            className="flex flex-col items-center md:items-start"
          >
            <Stack className={"items-center md:items-start"}>
              <Text
                className={"text-white "}
                variant="footerHeading"
                style={{ fontWeight: 700 }}
              >
                Navigate
              </Text>
              <ul className="mt-4 flex flex-col gap-2 items-center md:items-start">
                {navs?.map((value, key) => (
                  <li key={key} className="cursor-pointer">
                    <Link to={value?.path}>
                      <Text
                        variant="footerHeading"
                        style={{ color: "#ECECEC" }}
                      >
                        {value?.label}
                      </Text>
                    </Link>
                  </li>
                ))}
              </ul>
            </Stack>
          </div>
          {/* socila media section */}
          <div item xs={12} md={3} className="flex flex-col items-start">
            <Stack className="w-[100%] items-center md:items-start">
              <Stack
                flexDirection={"row"}
                gap={"16px"}
                mb={"16px"}
                className={"!flex-row gap-4 mb-4"}
              >
                {/* social media icons */}
                {socialMediaLinks?.map((value, ind) => (
                  <Stack
                    key={ind}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: Themes.colors.primary,
                    }}
                  >
                    <Stack style={{ width: "20px", height: "20px" }}>
                      <Link to={value?.url}>
                        <img src={value?.icon} alt={value?.alt} />
                      </Link>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              <Text variant="footerHeading" style={{}}>
                Wanna know more about us? <br />
                Explore our social media platforms{" "}
              </Text>
            </Stack>
          </div>
          {/* Get in touch */}
          <div
            item
            xs={12}
            md={4}
            className="flex flex-col items-start lg:items-center"
          >
            <Stack className="gap-2 md:gap-[14px] ">
              <Text
                className="text-center md:text-left"
                variant="p"
                style={{
                  color: "white",
                  fontWeight: 700,
                  marginBottom: "22px",
                }}
              >
                Get in Touch{" "}
              </Text>
              <Stack className={"!flex-row"} style={{ gap: "16px" }}>
                <Stack>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 5.13 8.13 2 12 2ZM7 9C7 11.85 9.92 16.21 12 18.88C14.12 16.19 17 11.88 17 9C17 6.24 14.76 4 12 4C9.24 4 7 6.24 7 9ZM12 11.5C10.6193 11.5 9.5 10.3807 9.5 9C9.5 7.61929 10.6193 6.5 12 6.5C13.3807 6.5 14.5 7.61929 14.5 9C14.5 10.3807 13.3807 11.5 12 11.5Z"
                      fill={"#ffe7d2"}
                    />
                  </svg>
                </Stack>
                <Text variant="p" style={{ color: "white" }}>
                  <Link target="_blank" href={footerData.locationUrl}>
                    {footerData.location}
                  </Link>
                </Text>
              </Stack>
              <Stack className={"!flex-row"} style={{ gap: "16px" }}>
                <Stack>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                      fill={"#ffe7d2"}
                    />
                  </svg>
                </Stack>
                <Text variant="p" style={{ color: "#ffffff" }}>
                  <Link href={`mailto:${footerData.email}`}>
                    {footerData.email}
                  </Link>
                </Text>
              </Stack>
              <Stack className={"!flex-row"} style={{ gap: "16px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
                    fill={"#ffe7d2"}
                  />
                </svg>
                <Text variant="p" style={{ color: "#ffffff" }}>
                  <Link to={`tel:${footerData.phone}`}>{footerData.phone}</Link>
                </Text>
              </Stack>
            </Stack>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
