import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { rightImg, watchImg } from "../utils";

import VideoCarousel from "./VideoCarousel";
import Text from "./Typography/Index";
import useSanityApi from "../hooks/useSanityApi";
import SanityRequest from "../utils/SanityRequest";

const fetchPortfolio = () => {
  return SanityRequest.fetchSanityRequest("portfolio");
};

const Highlights = () => {
  useGSAP(() => {
    gsap.to("#title", { opacity: 1, y: 0 });
    gsap.to(".link", { opacity: 1, y: 0, duration: 1, stagger: 0.25 });
  }, []);

  const portfolio = useSanityApi(fetchPortfolio);

  return (
    <section
      id="highlights"
      className="w-screen overflow-hidden h-full mt-[48px] "
    >
      <div className="screen-max-width">
        <div className="mb-12 w-full  md:flex items-end justify-center text-center">
          <Text
            variant="heading"
            className={"font-semibold text-center"}
            id="title"
          >
            Engage with Our Portfolio{" "}
          </Text>
        </div>

        <VideoCarousel portfolio={portfolio} />
      </div>
    </section>
  );
};

export default Highlights;
