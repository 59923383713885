import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { navLists, navs } from "../constants";
import images from "../constants/Images";
import Themes from "../constants/Themes";
import { useEffect, useState } from "react";
import useScrollPercentage from "../hooks/useScrollPercentage";
import Text from "./Typography/Index";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Navbar = () => {
  const scrollPercentage = useScrollPercentage(1);
  useGSAP(() => {
    gsap.to("#navbar", {
      translateY: 0,
      ease: "linear",
      duration: 0.7,
      opacity: 1,
    });
  }, []);
  return (
    <header
      id="navbar"
      className="w-full md:navbar fixed md:bg-light  z-50 px-5 sm:px-10 2xl:px-[90px] 3xl:px-[150px]  flex justify-between items-center translate-y-[-100px] "
    >
      <nav className="flex w-full  items-center justify-between ">
        <img
          className="invisible lg:visible "
          src={images.appzortNavbarLogo}
          alt="Apple"
          width={92}
          height={98}
        />
        <div className="flex flex-row items-center gap-5 ">
          <div className="flex flex-1 justify-center max-sm:hidden">
            {navs.map((nav, ind) => (
              <Text
                variant="footerHeading"
                key={ind}
                className="px-5 text-sm cursor-pointer !text-[#323130] hover:text-primary transition-all"
              >
                <a href={nav?.path}>{nav?.label}</a>
              </Text>
            ))}
          </div>

          <div className="flex  gap-7  max-sm:flex-1 cursor-pointer bg-[#FFE6CF] w-[44px] h-[44px] rounded-full justify-center items-center   ">
            <CircularProgressbar
              value={scrollPercentage}
              background={false}
              styles={buildStyles({
                pathTransitionDuration: 0,
                pathColor: Themes.colors.primary,
                strokeLinecap: "red",
                trailColor: "#FFE6CF",
              })}
            />
            <img
              src={images.menu}
              className="absolute z-10"
              alt="search"
              width={24}
              height={24}
            />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
