import React, { useEffect, useState } from "react";

const useSanityApi = (getFetch) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getFetch();
        setData(response);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return { data, loading };
};

export default useSanityApi;
