import {
  blackImg,
  blueImg,
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  whiteImg,
  yellowImg,
} from "../utils";
import images from "./Images";
import dummyVideo from "../assets/videos/WhatsApp Image 2024-04-14 at 1.06.39 PM.mp4";

export const provides = ["Design", "Development", "Marketing", ""];

export const services = [
  {
    icon: images.card1,
    color: "#F0FCFF",
    title: "Design - UX/UI  & Graphic Designing",
    description:
      "We craft visually stunning and intuitively functional interfaces that captivate and delight users.",
  },
  {
    icon: images.card2,
    color: "#FEEFFF",
    title: `Website \nDevelopment`,
    description:
      "We blend creativity and strategic thinking to produce compelling visual assets that resonate with your target audience.",
  },
  {
    icon: images.card1,
    color: "#F0FCFF",
    title: "Mobile  Application Development",
    description:
      "Turn your vision into vibrant mobile solutions. We specialise in crafting high-",
  },
];

export const aboutUs = [
  {
    label: "4+ Years",
    value: "Experience",
  },
  {
    label: "50+",
    value: "Projects",
  },
  {
    label: "20+",
    value: "Clients",
  },
  {
    label: "70000+",
    value: "Users",
  },
];

export const chooseUs = {
  title: "Why Choose us ?",
  description:
    "Appzort specializes in seamless business management solutions, ideal for scaling your operations. With tailored approaches, we simplify handling increased sales and workload, ensuring your business thrives effortlessly.",
  points: [
    "To Automate Business Process",
    "To Boost Customer Interaction",
    "To enhance efficiency",
    "To Improve Productivity",
    "To Optimise Time Management",
    "To Go Paperless",
    "To Increase Accessibility",
    "To Increase Accountability",
  ],
};

export const contactUs = [
  {
    label: "Email",
    value: "appzort@gmail.com",
    icon: images.sms,
    decoration: true,
    isMail: true,
  },
  {
    label: "Call Us",
    value: 7989527468,
    icon: images.call,
    isPhone: true,
  },
  {
    label: "Location",
    value: "India",
    icon: images.location,
  },
];

export const ourVision = {
  title: "Our Vision",
  description:
    "To become the foremost and most reliable Digital Transformation Consulting firm worldwide, dedicated to crafting exceptional and enriching user experiences on a global scale.",
};
export const footerData = {
  links: {},
  location: "Hebbal, Bengaluru, Karnataka",
  locationUrl: "",
  email: "appzort@gmail.com",
  phone: "7989527468",
};
export const navs = [
  {
    label: "Home",
    path: "#hero",
    icon1: images.menuHome,
    icon2: images.menuHomeBlack,
  },
  {
    label: "Services",
    path: "#services-section",
    icon1: images.menuServices,
    icon2: images.menuServicesBlack,
  },
  {
    label: "About Us",
    path: "#aboutus",
    icon1: images.menuAboutUs,
    icon2: images.menuAboutUsBlack,
  },

  {
    label: "Our Porfolio",
    path: "#highlights",
    icon1: images.menuGallery,
    icon2: images.menuGalleryBlack,
  },
];

export const testimonials = [
  {
    image: images.testimonialProfile1,
    userName: "Hannah Schmitt 1",
    role: "Lead designer",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, ",
  },
  {
    image: images.testimonialProfile1,
    userName: "Hannah Schmitt 2",
    role: "Lead designer",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, ",
  },
  {
    image: images.testimonialProfile1,
    userName: "Hannah Schmitt 3",
    role: "Lead designer",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, ",
  },
  {
    image: images.testimonialProfile1,
    userName: "Hannah Schmitt 4",
    role: "Lead designer",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, ",
  },
];

export const socialMediaLinks = [
  // {
  //   icon: images.twitterIcon,
  //   icon2: images.blackTwitter,
  //   url: "https://x.com/Sadbhavevents?t=-9r3el9dlX8NtofNDhNShQ&s=08",
  //   alt: "twitter",
  // },
  {
    icon: images.instagram,
    icon2: images.blackInstagram,
    url: "https://www.instagram.com/appzort/",
    alt: "instagram",
  },
  {
    icon: images.facebookIcon,
    icon2: images.blackFacebook,
    url: "https://www.facebook.com/profile.php?id=61555981903914",
    alt: "facebook",
  },
];

export const navLists = ["Store", "Mac", "iPhone", "Support"];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "Enter A17 Pro.",
      "Game‑changing chip.",
      "Groundbreaking performance.",
    ],
    video:
      "https://it8gdbpq5b8umkwy.public.blob.vercel-storage.com/explore-WaNws4EucnRIhOpB0aUa91hCiDwoql.mp4",
    videoDuration: 4,
  },
  {
    id: 2,
    textLists: ["Titanium.", "So strong. So light. So Pro."],
    video: highlightSecondVideo,
    videoDuration: 5,
  },
  {
    id: 3,
    textLists: [
      "iPhone 15 Pro Max has the",
      "longest optical zoom in",
      "iPhone ever. Far out.",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
  },
  {
    id: 4,
    textLists: ["All-new Action button.", "What will yours do?."],
    video: highlightFourthVideo,
    videoDuration: 3.63,
  },
];

export const models = [
  {
    id: 1,
    title: "iPhone 15 Pro in Natural Titanium",
    color: ["#8F8A81", "#ffe7b9", "#6f6c64"],
    img: yellowImg,
  },
  {
    id: 2,
    title: "iPhone 15 Pro in Blue Titanium",
    color: ["#53596E", "#6395ff", "#21242e"],
    img: blueImg,
  },
  {
    id: 3,
    title: "iPhone 15 Pro in White Titanium",
    color: ["#C9C8C2", "#ffffff", "#C9C8C2"],
    img: whiteImg,
  },
  {
    id: 4,
    title: "iPhone 15 Pro in Black Titanium",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
  "Privacy Policy",
  "Terms of Use",
  "Sales Policy",
  "Legal",
  "Site Map",
];
