import React from "react";

const SvgImage = ({ color, ...restProps }) => {
  return (
    <svg
      {...restProps}
      id="wave"
      style={{ transform: "rotate(0deg)", transition: "0.3s" }}
      viewBox="0 0 1440 490"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stopColor={color} offset="0%"></stop>
          <stop stopColor={color} offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style={{ transform: "translate(0, 0px)", opacity: 1 }}
        fill="url(#sw-gradient-0)"
        d="M0,98L80,81.7C160,65,320,33,480,73.5C640,114,800,229,960,228.7C1120,229,1280,114,1440,65.3C1600,16,1760,33,1920,40.8C2080,49,2240,49,2400,98C2560,147,2720,245,2880,302.2C3040,359,3200,376,3360,392C3520,408,3680,425,3840,432.8C4000,441,4160,441,4320,432.8C4480,425,4640,408,4800,400.2C4960,392,5120,392,5280,343C5440,294,5600,196,5760,179.7C5920,163,6080,229,6240,245C6400,261,6560,229,6720,245C6880,261,7040,327,7200,343C7360,359,7520,327,7680,285.8C7840,245,8000,196,8160,212.3C8320,229,8480,310,8640,294C8800,278,8960,163,9120,106.2C9280,49,9440,49,9600,73.5C9760,98,9920,147,10080,155.2C10240,163,10400,131,10560,122.5C10720,114,10880,131,11040,147C11200,163,11360,180,11440,187.8L11520,196L11520,490L11440,490C11360,490,11200,490,11040,490C10880,490,10720,490,10560,490C10400,490,10240,490,10080,490C9920,490,9760,490,9600,490C9440,490,9280,490,9120,490C8960,490,8800,490,8640,490C8480,490,8320,490,8160,490C8000,490,7840,490,7680,490C7520,490,7360,490,7200,490C7040,490,6880,490,6720,490C6560,490,6400,490,6240,490C6080,490,5920,490,5760,490C5600,490,5440,490,5280,490C5120,490,4960,490,4800,490C4640,490,4480,490,4320,490C4160,490,4000,490,3840,490C3680,490,3520,490,3360,490C3200,490,3040,490,2880,490C2720,490,2560,490,2400,490C2240,490,2080,490,1920,490C1760,490,1600,490,1440,490C1280,490,1120,490,960,490C800,490,640,490,480,490C320,490,160,490,80,490L0,490Z"
      ></path>
    </svg>
  );
};

export default SvgImage;
