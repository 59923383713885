import React, { useState } from "react";
import Text from "../../../components/Typography/Index";
import TestimonialCard from "../../../components/Ui/TestimonialCard";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Container from "../../../components/Ui/Container";
import images from "../../../constants/Images";
import { testimonials } from "../../../constants";

const Testimonials = () => {
  const [currentIndex, setCuurentIndex] = useState(0);
  const [testimonialsData, setTestimonialsData] = useState(testimonials);

  useGSAP(() => {
    if (currentIndex === testimonialsData?.length - 1) {
      setTestimonialsData((p) => [...p, ...testimonials]);
    }
  }, [currentIndex]);

  const slideRight = () => {
    gsap.to("#current-item", {
      translateX: 400,
      opacity: 0,
      duration: 1.5,
    });
  };

  const slideLeft = () => {
    gsap.to("#prev-item", {
      translateX: 0,
      opacity: 1,
      duration: 1.5,
    });
  };

  return (
    <div>
      <section className="w-full relative  flex-1 py-[52px] pb-[70px] md:pb-[182px] bg-secondary text-center overflow-hidden ">
        <Text
          variant="heading"
          className={"!text-light text-[20px] !font-semibold  md:!font-medium "}
        >
          Pay Attention to Our Clients Feedback
        </Text>
        <Container className="items-center justify-end    grid lg:grid-cols-[1fr,3fr,1fr] pt-[80px] md:pt-[142px]">
          {currentIndex !== 0 ? (
            <div
              onClick={() => {
                setCuurentIndex((p) => p - 1);
                slideLeft();
              }}
              className="w-[69px] h-[69px] hover:scale-[0.9] hidden cursor-pointer z-10  lg:flex bg-light testimonial__box--shadow rounded-full justify-center  items-center "
            >
              <img src={images.leftArrow} className="w[24px] h-[24px] " />
            </div>
          ) : (
            <div />
          )}
          <div className="relative">
            <img
              src={images.ovalshape}
              className="absolute w-[589px] right-[-90px] top-[-90px] hidden md:block "
            />
            {testimonialsData?.map((item, ind) => {
              return (
                <div
                  key={ind}
                  className={
                    currentIndex === ind
                      ? "relative z-10"
                      : "absolute z-0  top-0 "
                  }
                >
                  <TestimonialCard
                    id={
                      currentIndex === ind
                        ? "current-item"
                        : currentIndex - 1 === ind
                        ? "prev-item"
                        : currentIndex + 1 === ind && "next-item"
                    }
                    style={{
                      transform: `scale(${
                        ind === currentIndex + 1 ? 1.04 : 1
                      },${ind === currentIndex + 1 ? 0.9 : 1})`,
                    }}
                    className={""}
                    key={ind}
                    {...item}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-end">
            <div
              onClick={() => {
                setCuurentIndex((p) => p + 1);
                slideRight();
              }}
              className="w-[69px] h-[69px] cursor-pointer z-10  hidden hover:scale-[0.9]  lg:flex bg-light testimonial__box--shadow rounded-full justify-center  items-center "
            >
              <img src={images.rightArrow} className="w[24px] h-[24px]  " />
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Testimonials;
