import React from "react";
import BottomPanel from "bottom-panel-react";

const BottomSheet = ({
  panelState,
  panelDragIcon,
  isOpen,
  children,
  ...restProps
}) => {
  return (
    <BottomPanel
      isVisible={isOpen}
      panelState={panelState} // Set Initial State of Panel from ["top", "bottom", "middle"]
      isNavigationButtons={false}
      isKeysFunctional={false}
      getPanelState={(value) => {}} // Get the Current State of Panel
      panelDragIcon={panelDragIcon || null}
      {...restProps}
    >
      {children}
    </BottomPanel>
  );
};

export default BottomSheet;
