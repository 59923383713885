import React from "react";
import images from "../../constants/Images";
import Text from "../Typography/Index";

const TestimonialCard = ({
  image,
  userName,
  role,
  content,
  className,

  ...restProps
}) => {
  return (
    <div
      className={
        "testimonial__box--shadow px-[12px] md:px-[48px] bg-light  rounded-[20px]" +
        " " +
        className
      }
      {...restProps}
    >
      <div className="text-center flex flex-col items-center relative top-[-50px]">
        <img
          src={image}
          className="w-[102px] h-[102px] rounded-full"
        />
        <Text
          variant="subheading"
          className={"mt-[8px] font-semibold !text-[#3D3D3D]"}
        >
          {userName}
        </Text>
        <Text variant="testDescription" className={" !text-[#3D3D3D] mt-[7px]"}>
          {role}
        </Text>
        <Text
          variant="testDescription"
          className={" !text-[#3D3D3D] mt-[26px]  "}
        >
          {content}
        </Text>
      </div>
    </div>
  );
};

export default TestimonialCard;
