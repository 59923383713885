import { Controls, Player } from "@lottiefiles/react-lottie-player";
import React from "react";

const LottiePlayer = ({ style, src, isVisible, ...props }) => {
  return (
    <Player
      {...props}
      autoplay
      loop
      src={src}
      style={{
        height: "100%",
        width: "100%",
        ...style,
      }}
    >
      <Controls
        visible={isVisible}
        buttons={["play", "repeat", "frame", "debug"]}
      />
    </Player>
  );
};

export default LottiePlayer;
