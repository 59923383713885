"use client";

const Container = ({ children, style, className, ...restProps }) => {
  return (
    <div
      style={style}
      className={` mx-auto px-[15px] md:px-5 lg:px-[40px] 2xl:px-[90px] 3xl:px-[150px] h-[100%]  ${className}`}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Container;
