import { client } from "../config/client";

class SanityRequest {
  static fetchSanityRequest = async (slug) => {
    const query = `*[_type == "${slug}" && !(_id in path("drafts.**"))]`;
    const products = await client.fetch(query, undefined, {
      cache: "no-store",
    });
    return products;
  };
}

export default SanityRequest;
