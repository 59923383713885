import React, { useEffect, useState } from "react";
import Container from "../../../components/Ui/Container";
import Text from "../../../components/Typography/Index";
import Cards from "../../../components/Ui/Cards";
import { services } from "../../../constants";
import Primary from "../../../components/button/Primary";
import Slider from "../../../components/common/carousel/Slider";
import images from "../../../constants/Images";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import SanityRequest from "../../../utils/SanityRequest";
import BottomSheet from "../../../components/common/sheet/BottomSheet";
import ServicesModal from "./modals/Services";
import { getOrder } from "../../../utils/helpers";

const fetchServices = () => {
  return SanityRequest.fetchSanityRequest("services");
};
const fetchDiveInServices = () => {
  return SanityRequest.fetchSanityRequest("diveInServices");
};

const Services = ({ open, setOpen }) => {
  const [services, setServices] = useState([]);
  const [diveInServices, setDiveInServices] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetchServices();
      setServices(getOrder(response, "order"));
    })();
    (async () => {
      const response = await fetchDiveInServices(diveInServices);
      setDiveInServices(response);
    })();
  }, []);

  useGSAP(() => {
    if (open) {
      gsap.to("body", {
        overflow: "hidden",
      });

      gsap.to("#navbar", {
        translateY: -100,
        ease: "linear",
        duration: 0.5,
        opacity: 0,
      });
      gsap.to(".panel__services", {
        delay: 0.1,
        height: "92%",
      });
      // gsap.to(".panel__services", {
      //   scrollTrigger: ".panel__services__section",
      //   // start: "top center", // Start the animation when the top of the trigger element reaches the center of the viewport
      //   height: "100%", // Set height to "auto" to make it 100% of its content height
      // });
      return;
    }
    gsap.to("body", {
      overflow: "auto",
    });
    gsap.to("#navbar", {
      translateY: 0,
      ease: "linear",
      duration: 0.5,
      opacity: 1,
    });
  }, [open]);

  useGSAP(() => {
    gsap.to("#services-section", {
      translateX: 0,
      ease: "linear",
      duration: 1,
      opacity: 1,
      delay: 0.5,
    });
  }, []);

  return (
    <>
      <section
        id="services-section"
        className="w-full  relative overflow-hidden pb-[30px] pt-4 md:pb-[48px] translate-x-[50px] md:translate-x-[100px] opacity-0"
      >
        {open && (
          <div className="fixed w-full h-full bg-[#000000] bg-opacity-60 top-0 bottom-0 z-40 " />
        )}
        <Container className={"!pr-0"}>
          <Text variant="subheading" className={"!text-primary "}>
            Our Expertise{" "}
          </Text>
          <Text
            variant="heading"
            className={"mt-[16px] md:w-3/5 text-secondary  "}
          >
            Crafting Seamless Experiences Across Design and Development
          </Text>
          <div className="mt-[24px] gap-[18px] md:gap-[32px] bg-light">
            {/* cards goes here */}
            <Slider
              autoPlay={true}
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside
              renderDotsOutside={false}
              arrows={false}
              customButtonGroup={<CustomButton setOpen={setOpen} />}
              partialVisible={true}
              className="carousel"
              infinite={true}
              swipeable={true}
              draggable={true}
              itemClass="carousel-item-padding-40-px"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              autoPlaySpeed={1900}
            >
              {services?.map((item, key) => (
                <Cards {...item} key={key} ind={key} />
              ))}
            </Slider>
          </div>
        </Container>
      </section>
      <div className=" relative z-50 ">
        <BottomSheet
          // topHeight={730}
          panelDragIcon={<div />}
          panelState={open ? "top" : "bottom"}
          isOpen={open}
          panelClass={"panel__services md:w-[97%] left-0 right-0 m-auto  "}
        >
          <ServicesModal
            diveInServices={diveInServices}
            handleClose={() => setOpen(false)}
          />
        </BottomSheet>
      </div>
    </>
  );
};

const CustomButton = (props) => {
  useGSAP(() => {
    gsap.to("#services", {
      scrollTrigger: {
        trigger: "#services",
        toggleActions: "restart none none none", // Restart the animation when entering the viewport, do nothing when leaving
      },
      width: "auto",
      height: "60px",
      padding: "auto",
      duration: 0.8,
      delay: 0.8,
    });
    gsap.to(".service_text", {
      scrollTrigger: {
        trigger: "#services",
        toggleActions: "restart none none none", // Restart the animation when entering the viewport, do nothing when leaving
      },
      delay: 1.8,
      opacity: 1,
      start: "top top",
      end: "+=100",
      scrub: 1,
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-center  mt-2 relative">
        <div className="flex gap-4  self-end absolute mr-10  top-0 ">
          <div
            className="w-[60px] h-[60px] hidden cursor-pointer  lg:flex bg-red testimonial__box--shadow rounded-full justify-center  items-center "
            onClick={props?.previous}
          >
            <img src={images.arrowbrokenLeft} className="w[24px] h-[24px] " />
          </div>
          <div
            className="w-[60px] h-[60px] hidden  cursor-pointer lg:flex bg-red testimonial__box--shadow rounded-full justify-center  items-center "
            onClick={props?.next}
          >
            <img src={images.arrowbrokenRight} className="w[24px] h-[24px] " />
          </div>
        </div>
        <Primary
          onClick={() => props?.setOpen?.(true)}
          id="services"
          variant="secondary"
          className="w-[70px] h-[70px]  "
        >
          <span className="items-center flex service_text opacity-0 ">
            Dive into in- detail Services
            <span>
              <img
                className="w-[24px] h-[24px] ml-2 "
                src={images.arrowbrokenRight}
              />
            </span>
          </span>
        </Primary>
      </div>
    </>
  );
};

export default Services;
